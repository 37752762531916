import React, { useState, useRef, useEffect, useCallback } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faExclamationCircle, faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Client } from '@microsoft/microsoft-graph-client';
import EmailItem from './email-list/EmailItem';
import './EmailsList.css';
import { useMsal } from '@azure/msal-react';
import { useSelector } from 'react-redux';

const EmailsList = ({ emails, searchResults, setEmails, setSearchResults, onSelectEmail, onLoadMore, selectedMailbox, selectedFolder, className, onWidthChange }) => {
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  const [expandedEmailId, setExpandedEmailId] = useState(null);
  const [forceFetchState, setForceFetchState] = useState({ loading: false, newEmailCount: 0 });
  const [newEmailIds, setNewEmailIds] = useState([]); // 新增邮件ID状态
  const observer = useRef(null);
  const emailListRef = useRef(null);
  const idPattern = /ID\d{10}/;
  const { instance, accounts } = useMsal();
  const userEmail = useSelector(state => state.user.email);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect.width < 380) {
          onWidthChange(false);
        } else {
          onWidthChange(true);
        }
      }
    });

    if (emailListRef.current) {
      resizeObserver.observe(emailListRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [onWidthChange]);

  const handleEmailClick = (email) => {
    setSelectedEmailId(email.id);
    onSelectEmail(email);
  };

  const handleExpandClick = (email, isSearchResults = false) => {
    if (expandedEmailId === email.id) {
      setExpandedEmailId(null); // 收缩时关闭
    } else {
      setExpandedEmailId(email.id); // 展开时显示线程和主邮件副本
      // 如果主题包含ID，自动触发forceFetch
      const idMatch = email.subject.match(idPattern);
      if (idMatch) {
        forceFetch(idMatch[0], isSearchResults); // 传入ID进行forceFetch，并传递是否是搜索结果
      }
    }
  };
  
  

  const thirdLastEmailRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          onLoadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [onLoadMore]
  );

  const forceFetch = async (subjectId, isSearchResults = false) => {
    setForceFetchState({ loading: true, newEmailCount: 0 });
    
    try {
      const account = accounts[0];
      const response = await instance.acquireTokenSilent({
        scopes: ['Mail.Read', 'Mail.Read.Shared'],
        account: account,
      });
  
      const client = Client.init({
        authProvider: (done) => {
          done(null, response.accessToken);
        },
      });
  
      const userMailbox = selectedMailbox === 'Support'
        ? 'support-pool@fraba.com'
        : (selectedMailbox === 'Personal' ? userEmail : selectedMailbox);
  
      const result = await client.api(`/users/${userMailbox}/messages`)
        .filter(`contains(subject, '${subjectId}')`)
        .select('id,subject,from,sender,receivedDateTime,toRecipients,ccRecipients,bodyPreview,isRead,categories,hasAttachments,importance')
        .get();
  
      const fetchedEmails = result.value || [];
  
      const sanitizedEmails = fetchedEmails.map(email => ({
        ...email,
        toRecipients: email.toRecipients || [],
        ccRecipients: email.ccRecipients || [],
        categories: email.categories || [],
      }));
  
      // 去重逻辑
      const newEmails = sanitizedEmails.filter(fetchedEmail => 
        !emails.some(existingEmail => 
          existingEmail.id === fetchedEmail.id || 
          (existingEmail.threadMessages && existingEmail.threadMessages.some(threadMessage => threadMessage.id === fetchedEmail.id))
        )
      );
  
      setNewEmailIds(newEmails.map(email => email.id));
  
      if (isSearchResults) {
        // 如果是在搜索结果中，更新搜索结果
        setSearchResults(prevSearchResults => prevSearchResults.map(email => {
          if (email.subject.includes(subjectId)) {
            const updatedThreadMessages = [
              ...email.threadMessages.filter(msg => !newEmails.some(newMsg => newMsg.id === msg.id)),
              ...newEmails
            ].sort((a, b) => new Date(a.receivedDateTime) - new Date(b.receivedDateTime));
  
            return {
              ...email,
              threadMessages: updatedThreadMessages,
            };
          }
          return email;
        }));
      } else {
        // 如果是在普通的邮件列表中，更新 emails 列表
        setEmails(prevEmails => prevEmails.map(email => {
          if (email.subject.includes(subjectId)) {
            const updatedThreadMessages = [
              ...email.threadMessages.filter(msg => !newEmails.some(newMsg => newMsg.id === msg.id)),
              ...newEmails
            ].sort((a, b) => new Date(a.receivedDateTime) - new Date(b.receivedDateTime));
  
            return {
              ...email,
              threadMessages: updatedThreadMessages,
            };
          }
          return email;
        }));
      }
  
      if (newEmails.length === 0) {
        // 如果没有新邮件，显示"No new emails"消息
        setForceFetchState({ loading: false, newEmailCount: 'none' });
  
        // 1.5秒后消失按钮
        setTimeout(() => {
          setForceFetchState({ loading: false, newEmailCount: null }); // 隐藏按钮
        }, 1500);
      } else {
        // 有新邮件时正常显示
        setForceFetchState({ loading: false, newEmailCount: newEmails.length });
  
        setTimeout(() => {
          setForceFetchState({ loading: false, newEmailCount: 0 });
        }, 1500);
      }
  
    } catch (error) {
      console.error("Error fetching emails:", error);
      setForceFetchState({ loading: false, newEmailCount: 0 });
    }
  };
  

  return (
    <div ref={emailListRef} className={`elx-email-list ${className}`}>
      {emails.map((email, index) => {
        const isSearchResults = searchResults.length > 0;
        const allMessages = [
          { ...email, isMainEmail: true },
          ...(email.threadMessages || [])
        ].sort((a, b) => new Date(b.receivedDateTime) - new Date(a.receivedDateTime));

        const containsId = idPattern.test(email.subject);

        return (
          <div
            key={email.id}
            className={`elx-email-entity ${email.threadMessages && email.threadMessages.length > 0 ? 'has-footer' : ''} ${expandedEmailId === email.id ? 'expanded' : ''}`}
            ref={emails.length === index + 1 ? thirdLastEmailRef : null}
          >
    
            <div
              className={`elx-email-content ${email.id === selectedEmailId ? 'selected' : ''}`}
              onClick={() => handleEmailClick(email)}
            >
              <EmailItem
                id={email.id}
                subject={email.subject}
                from={email.from}
                receivedDateTime={email.receivedDateTime}
                toRecipients={email.toRecipients}
                ccRecipients={email.ccRecipients}
                isRead={email.isRead}
                categories={email.categories}
                hasAttachments={email.hasAttachments}
                importance={email.importance}
                bodyPreview={email.bodyPreview}
                selectedMailbox={selectedMailbox}
                selectedFolder={selectedFolder}
              />
            </div>
            {email.threadMessages && email.threadMessages.length > 0 && (
              <div className="elx-email-footer">
                <AnimatePresence>
                  {containsId && expandedEmailId === email.id && forceFetchState.newEmailCount !== null && (
                    <motion.button
                      className="elx-force-retrieve-button"
                      initial={{ opacity: 0, x: 15 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 15 }} // 退出时的动画
                      transition={{ duration: 0.15 }} // 动画持续时间
                      onClick={(e) => {
                        e.stopPropagation();
                        const idMatch = email.subject.match(idPattern);
                        if (idMatch) {
                          forceFetch(idMatch[0]); // 传入ID
                        }
                      }}
                    >
                      {forceFetchState.loading ? (
                        <>
                          <FontAwesomeIcon icon={faSpinner} spin /> Fetching...
                        </>
                      ) : (
                        forceFetchState.newEmailCount === 'none' ? (
                          <>
                            <FontAwesomeIcon icon={faExclamationCircle} /> No extra emails found
                          </>
                        ) : (
                          forceFetchState.newEmailCount > 0 ? (
                            <>
                              <FontAwesomeIcon icon={faCheckCircle} /> {forceFetchState.newEmailCount} new emails fetched
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon icon={faExclamationCircle} /> Force Fetch
                            </>
                          )
                        )
                      )}
                    </motion.button>
                  )}
                </AnimatePresence>
                <button
                  className="elx-expand-button"
                  onClick={(e) => { e.stopPropagation(); handleExpandClick(email, isSearchResults); }}
                >
                  <span className="elx-thread-count">{email.threadMessages.length}</span>
                  <FontAwesomeIcon icon={expandedEmailId === email.id ? faChevronUp : faChevronDown} />
                </button>
              </div>
            )}
            <AnimatePresence>
              {expandedEmailId === email.id && (
                <motion.div
                  className="elx-thread-messages"
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                >
                  {allMessages.map((message) => (
                    <div
                      key={message.id}
                      className={`elx-thread-message ${message.id === selectedEmailId ? 'selected' : ''} ${newEmailIds.includes(message.id) ? 'forced-new-email' : ''}`}
                      onClick={(e) => { e.stopPropagation(); handleEmailClick(message); }}
                    >
                      <EmailItem
                        id={message.id}
                        subject={message.subject}
                        from={message.from}
                        receivedDateTime={message.receivedDateTime}
                        toRecipients={message.toRecipients}
                        ccRecipients={message.ccRecipients}
                        isRead={message.isRead}
                        categories={message.categories}
                        hasAttachments={message.hasAttachments}
                        importance={message.importance}
                        bodyPreview={message.bodyPreview}
                        selectedMailbox={selectedMailbox}
                        selectedFolder={selectedFolder}
                      />
                    </div>

                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        );
      })}
    </div>
  );
};

export default EmailsList;
