// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { Provider, useSelector } from 'react-redux';
import { msalInstance } from './components/auth/authConfig';
import { setAccessTokenToBackend, startAutoRefreshToken } from './components/auth/AuthService';
import store from './store';
import HomePage from './components/auth/HomePage';
import EmailApp from './components/apps/email-app/EmailApp';
import MessageBox from './components/apps/messagebox-app/MessageBoxApp';
import MessageBoxFrame from './components/apps/messagebox-app/MessageBoxFrame';
import SupportEngine from './components/apps/support-engine/SupportEngine';
import UserFetcher from './components/UserFetcher';
import './index.css';

const AppContent = () => {
    const theme = useSelector((state) => state.theme);
    const { instance } = useMsal();
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);

        const initializeMsal = async () => {
            try {
                await instance.initialize();
                setIsInitialized(true);
                startAutoRefreshToken(); // 启动自动刷新令牌逻辑
            } catch (error) {
                console.error("MSAL initialization error:", error);
            }
        };

        initializeMsal();

        const callbackId = instance.addEventCallback((event) => {
            if (event.eventType === 'msal:acquireTokenSuccess' && event.payload.accessToken) {
                setAccessTokenToBackend(event.payload.accessToken);
            }
            if (event.eventType === 'msal:loginSuccess' && event.payload.accessToken) {
                setAccessTokenToBackend(event.payload.accessToken);
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [theme, instance]);

    if (!isInitialized) {
        return <div>Loading...</div>; // 或者返回一个加载组件
    }

    return (
        <>
            <UserFetcher /> {/* 在 MSAL 初始化完成后渲染 UserFetcher */}
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/mail" element={<EmailApp />} /> 
                    <Route path="/messagebox" element={<MessageBox />} /> 
                    <Route path="/messageboxapp" element={<MessageBoxFrame />} /> 
                    <Route path="/engine" element={<SupportEngine />} /> 
                </Routes>
            </Router>
        </>
    );
};

const App = () => {
    return (
        <Provider store={store}>
            <MsalProvider instance={msalInstance}>
                <AppContent />
            </MsalProvider>
        </Provider>
    );
};

export default App;
